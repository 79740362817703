import { Show } from "solid-js";

import type { ParagraphQa } from "~/types/drupal_jsonapi";

export default function ParagraphQAComponent(props: { qa: ParagraphQa }) {
  return (
    <>
      <div
        itemscope
        itemprop="mainEntity"
        itemtype="https://schema.org/Question"
        class="paragraph-faq"
        data-test="faq"
      >
        <Show when={props.qa.field_question}>
          <h3 itemprop="name">{props.qa.field_question}</h3>
        </Show>
        <Show when={props.qa.field_answer?.value}>
          <div
            itemscope
            itemprop="acceptedAnswer"
            itemtype="https://schema.org/Answer"
          >
            <div
              itemprop="text"
              data-test="answer"
              innerHTML={props.qa.field_answer!.value}
            />
          </div>
        </Show>
      </div>
    </>
  );
}
