import {
  AutopromosRenderer,
  hasMultipleShortcodes,
  mayHaveShortcode,
} from "~/components/shared/Autopromos/AutopromosRenderer";
import { For, Show } from "solid-js";
import CommonFirstScreen from "~/components/shared/CommonFirstScreen";
import CommonIntroduction from "~/components/shared/CommonIntroduction";
import JourneySections from "~/components/Journey/Components/JourneySections";
import ParagraphQAComponent from "~/components/shared/ParagraphQAComponent";
import RelatedContentComponent from "~/components/shared/RelatedContentComponent";
import ProgramsNearbyList from "~/components/Program/Components/ProgramsNearbyList";
import ContentSeoText from "~/components/shared/ContentSeoText";
import Breadcrumb from "~/components/shared/Breadcrumb";

import type { Journey, ParagraphQa } from "~/types/drupal_jsonapi";

import "~/components/shared/CommonPageStyle.css";
import "./Journey.css";
import GtmVars from "../shared/Trackers/GtmVars";
import { ProjectErrorBoundary } from "../shared/ProjectErrorBoundary";

export default function Journey(props: { journey: Journey }) {
  const gtmValues = () => {
    const breadcrumbItems = props.journey.breadcrumb.slice(1).toReversed();
    return {
      "all.pageType": "other",
      "all.mainCategory": breadcrumbItems.at(0)?.text ?? "Non défini",
      "all.category": breadcrumbItems.at(1)?.text ?? "Non défini",
      "all.subCategory": breadcrumbItems.at(2)?.text ?? "Non défini",
      "all.subsubCategory": breadcrumbItems.at(3)?.text ?? "Non défini",
      "all.subsubsubCategory": breadcrumbItems.at(4)?.text ?? "Non défini",
      template: "journey",
    };
  };

  return (
    <>
      <GtmVars values={gtmValues()} />
      <article class="common-page-style node-journey" data-ga-zone="in-page">
        <CommonFirstScreen fieldImage={props.journey.field_image} />

        <div class="outer-content-area">
          <section class="content-area">
            <div class="inner">
              <CommonIntroduction
                title={props.journey.title}
                catchline={props.journey.field_catchline}
              />

              {/* CUSTOM JOURNEYS OR DEFAULT SECTIONS */}
              <Show
                when={mayHaveShortcode(props.journey.field_catchline)}
                fallback={
                  <Show
                    when={
                      props.journey.field_sections &&
                      props.journey.field_sections.length > 0
                    }
                  >
                    <JourneySections journey={props.journey!} />
                  </Show>
                }
              >
                <AutopromosRenderer
                  text={props.journey.field_catchline}
                  fromFieldName="field_catchline"
                />
              </Show>

              {/* FAQ */}
              <Show when={props.journey.field_qas.length > 0}>
                <section
                  class="journey-faq"
                  itemscope
                  itemtype="https://schema.org/FAQPage"
                  data-test="faqs"
                >
                  <h2>Foire aux questions</h2>
                  <For each={props.journey.field_qas}>
                    {(qa: ParagraphQa) => (
                      <>
                        <ParagraphQAComponent qa={qa} />
                      </>
                    )}
                  </For>
                </section>
              </Show>
            </div>
          </section>

          {/* RELATED */}
          <Show when={props.journey.field_contents.length > 0}>
            <RelatedContentComponent contents={props.journey.field_contents} />
          </Show>
        </div>
      </article>

      {/* PROGRAMS */}
      <Show when={props.journey.programs.length > 0}>
        <section class="nearby-common-pages" data-test="programs-journey">
          <h2>Découvrez nos programmes</h2>
          <ProjectErrorBoundary>
            <ProgramsNearbyList nearbyPrograms={props.journey.programs} />
          </ProjectErrorBoundary>
        </section>
      </Show>

      {/* AUTOPROMOS */}
      <Show when={props.journey.field_ads}>
        <section
          class="autopromos-container"
          data-ga-zone="ads"
          data-test="autopromos"
          classList={{
            single: !hasMultipleShortcodes(props.journey.field_ads!),
            multiple: hasMultipleShortcodes(props.journey.field_ads!),
          }}
        >
          <AutopromosRenderer
            text={props.journey.field_ads!}
            fromFieldName="field_ads"
            journey={props.journey}
          />
        </section>
      </Show>

      {/* SEO BLOCK */}
      <Show
        when={
          props.journey.field_seo_block_body.length > 0 &&
          props.journey.field_seo_block_body.at(0)?.value
        }
      >
        <ContentSeoText
          title={props.journey.field_seo_block_title}
          bodies={props.journey.field_seo_block_body}
        />
      </Show>

      <Breadcrumb items={props.journey.breadcrumb.slice(1)} />
    </>
  );
}
