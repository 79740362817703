import {
  createEffect,
  createMemo,
  createSignal,
  For,
  onCleanup,
  onMount,
  Show,
} from "solid-js";
import { useViewportSizeContext } from "~/contexts/ViewportSizeContext";
import { isServer } from "solid-js/web";
import SectionParagraphComponent from "~/components/shared/SectionParagraphComponent";

import type { Journey, SectionParagraph } from "~/types/drupal_jsonapi";

import "./JourneyAnchors.css";

import IconExpandMore from "~/img/icons/expand_more.svg";

type AnchorSelectOption = {
  url: string;
  text: string;
};

export default function JourneySections(props: { journey: Journey }) {
  const areAnchorsEnabled = createMemo(() => {
    return (
      props.journey.field_sections!.filter(
        (section) =>
          section.type === "paragraph--section" &&
          section.field_anchor_title !== null,
      ).length > 0
    );
  });

  return (
    <>
      <div data-test="paragraphs">
        <Show
          when={
            props.journey.field_sections!.length > 1 &&
            !props.journey.field_sections!.at(0)?.field_anchor_title &&
            areAnchorsEnabled()
          }
          fallback={
            <>
              <For each={props.journey.field_sections!}>
                {(section: SectionParagraph, index) => (
                  <SectionParagraphComponent
                    section={section}
                    index={index() + 1}
                    journey={props.journey}
                  />
                )}
              </For>
            </>
          }
        >
          <SectionParagraphComponent
            section={props.journey.field_sections!.at(0)!}
            index={0}
          />
          <JourneyAnchors sections={props.journey.field_sections!.slice(1)} />
          <For each={props.journey.field_sections!.slice(1)}>
            {(section: SectionParagraph, index) => (
              <SectionParagraphComponent
                section={section}
                index={index() + 1}
                journey={props.journey}
              />
            )}
          </For>
        </Show>
      </div>
    </>
  );
}

function JourneyAnchors(props: { sections: SectionParagraph[] }) {
  const [viewPortState] = useViewportSizeContext();

  const [anchorsShouldStack, setAnchorsShouldStack] = createSignal(false);

  const [anchorReceiverHeight, setAnchorReceiverHeight] = createSignal(0);

  const [anchorOptions, setAnchorOptions] = createSignal<AnchorSelectOption[]>(
    [],
  );

  createEffect(() => {
    if (props.sections) {
      const array: AnchorSelectOption[] = [];
      props.sections.forEach((section, index) => {
        if (
          section.type === "paragraph--section" &&
          section.field_anchor_title
        ) {
          const finalIndex = index + 1;
          array.push({
            url: "#paragraph-" + finalIndex,
            text: section.field_anchor_title,
          });
        }
      });
      setAnchorOptions(array);
    }
  });

  onMount(() => {
    if (window.document.getElementById("anchors-stackable")) {
      setAnchorReceiverHeight(
        window.document
          .getElementById("anchors-stackable")!
          .getBoundingClientRect().height,
      );
    }
  });

  onMount(() => {
    document.addEventListener("scroll", handleScroll);
  });

  onCleanup(() => {
    if (!isServer) {
      document.removeEventListener("scroll", handleScroll);
    }
  });

  const handleScroll = () => {
    const anchorsReceiver = window.document.getElementById("anchors-receiver");
    if (
      anchorsReceiver &&
      anchorsReceiver.getBoundingClientRect().top +
        anchorsReceiver.getBoundingClientRect().height -
        (!viewPortState.viewPortIsLessThan768 ? 64 : 56) <=
        0
    ) {
      setAnchorsShouldStack(true);
    } else {
      setAnchorsShouldStack(false);
    }
  };

  return (
    <>
      <div
        id="anchors-receiver"
        style={{ height: anchorReceiverHeight() + "px" }}
      >
        <div
          class="section-anchors"
          id="anchors-stackable"
          classList={{ stacked: anchorsShouldStack() }}
          data-test="anchors"
        >
          <Show when={viewPortState.viewPortIsLessThan768}>
            <FormInputSelectAnchors datas={anchorOptions()} />
          </Show>
          <Show
            when={
              !viewPortState.viewPortIsLessThan768 ||
              (viewPortState.viewPortIsLessThan768 && !anchorsShouldStack())
            }
          >
            <For each={props.sections}>
              {(section, index) => (
                <>
                  <Show when={section.field_anchor_title}>
                    <a
                      href={`#paragraph-${index() + 1}`}
                      class="btn"
                      data-test="anchor"
                    >
                      {section.field_anchor_title}
                    </a>
                  </Show>
                </>
              )}
            </For>
          </Show>
        </div>
      </div>
    </>
  );
}

function FormInputSelectAnchors(props: { datas: AnchorSelectOption[] }) {
  const [inputHasContent, setInputHasContent] = createSignal(false);
  const [menuDialogIsActive, setMenuDialogIsActive] = createSignal(false);

  const [anchorIndex, setAnchorIndex] = createSignal<number | undefined>();

  createEffect(() => {
    if (
      typeof anchorIndex() !== "undefined" &&
      props.datas.at(anchorIndex()!)
    ) {
      setInputHasContent(true);
    } else {
      setInputHasContent(false);
    }
  });

  const inputValue = createMemo(() => {
    if (
      typeof anchorIndex() !== "undefined" &&
      props.datas.at(anchorIndex()!)
    ) {
      return props.datas.at(anchorIndex()!)?.text;
    }
    return undefined;
  });

  function setActiveAnchor(index: number) {
    setMenuDialogIsActive(false);
    setAnchorIndex(index);
    document.location.href = props.datas.at(index)!.url;
  }

  const [inputIsFocus, setInputIsFocus] = createSignal(false);

  return (
    <>
      <div
        class="form-control"
        classList={{
          "is-focus": inputIsFocus(),
          "has-content": inputHasContent(),
        }}
      >
        <div class="form-slot form-select">
          <label for="visit-select">Choisissez un paragraphe</label>
          <input
            id="visit-select"
            name="visit_select"
            type="text"
            value={inputValue() ? inputValue() : ""}
            readonly
            onFocusIn={() => {
              setInputIsFocus(true);
              setMenuDialogIsActive(true);
            }}
            onBlur={() => {
              setInputIsFocus(false);
            }}
            data-test="menu"
          />
          <i aria-hidden="true" class="cog-icon">
            <IconExpandMore />
          </i>
          <div class="menu-dialog" classList={{ active: menuDialogIsActive() }}>
            <div
              class="dialog-overlay"
              onClick={() => setMenuDialogIsActive(false)}
            />
            <ul class="list" data-test="menu-items">
              <For each={props.datas}>
                {(anchor: AnchorSelectOption, index) => (
                  <>
                    <li
                      onClick={() => setActiveAnchor(index())}
                      classList={{
                        active: index() === anchorIndex(),
                      }}
                      data-test="item"
                    >
                      {anchor.text}
                    </li>
                  </>
                )}
              </For>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
